(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:httpPrefix
   * @restrict A
   * @element
   *
   */
  angular
    .module('components')
    .directive('passwordVerify', passwordVerify);

  function passwordVerify() {
    return {
      restrict: 'A',
      require: '?ngModel',
      link: function (scope, _, attrs, ngModel) {
        if (!ngModel) {
          return;
        }
        function validate() {
          const val1 = ngModel.$viewValue,
              val2 = attrs.passwordVerify;
          let valid = val1 === val2;

          if (val2 === '' || angular.isUndefined(val2)) {
            valid = true;
          }
          ngModel.$setValidity('passwordVerify', valid);
        }
        scope.$watch(attrs.ngModel, function () {
          validate();
        });

        attrs.$observe('passwordVerify', function () {
          validate();
        });
      }
    };
  }
}());
